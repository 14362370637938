import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import { default as Marquee } from "./_marquee";
import { default as header } from "./_header";
import emerge from "./_emerge";

Alpine.plugin(collapse);
Alpine.plugin(emerge);

Alpine.store("header", header);

Alpine.data("Marquee", Marquee);

export default Alpine;
