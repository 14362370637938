// https://tailwindcss.com/docs/configuration

const plugin = require("tailwindcss/plugin");

module.exports = {
    content: [
        "./index.php",
        "./app/**/*.php",
        "./resources/**/*.{php,vue,js}",
        "!./resources/blocks/*.js",
        "./config/acf.php",
    ],
    corePlugins: {
        container: false,
    },
    theme: {
        extend: {
            fontFamily: {
                sans: ["General Sans", "sans-serif"],
                cursive: ["Comico", "cursive"],
            },
            fontSize: {
                scale: "clamp(var(--tw-text-scale-min), var(--tw-text-scale), var(--tw-text-scale-max))",
            },
            backgroundImage: {
                star: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 30' preserveAspectRatio='none' fill='none'%3E%3Cpath d='M1 15.6925C12.9386 14.3568 13.805 10.4588 15.3441 1C15.7896 11.9971 18.6851 13.778 30 15.0691C18.596 15.6925 16.6359 19.3878 15.3441 29.0937C13.8517 19.7624 12.1813 16.4493 1 15.6925Z' stroke='black' stroke-width='2.6756'/%3E%3C/svg%3E\")",
                circle: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='66' viewBox='0 0 182 66' fill='none' preserveAspectRatio='none'%3E%3Cpath d='M14.4197 37.5427C28.4815 45.819 57.3417 51.3875 98.0556 50.8702C152.282 50.1811 188.246 36.031 174.96 21.221C164.707 9.79226 121.905 6.91956 83.8909 9.02341M14.4197 37.5427C9.75276 34.7959 6.71584 31.7509 5.41656 28.5311C1.10246 17.8401 27.0131 5.51453 83.8909 9.02341M14.4197 37.5427C7.94794 27.2518 21.3725 16.7046 52.3736 12.1049C61.8158 10.7039 72.6438 9.64585 83.8909 9.02341M14.4197 37.5427C16.5815 40.9802 20.9632 44.3891 27.6515 47.5381C47.4861 56.8769 95.6484 62.1037 139.001 56.2492M83.8909 9.02341C95.7573 9.75547 108.972 11.1768 123.541 13.4459' stroke='%23DBFF42' stroke-width='8'/%3E%3C/svg%3E\")",
                "circle-2":
                    "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='137' height='101' viewBox='0 0 137 101' fill='none' preserveAspectRatio='none'%3E%3Cpath d='M10.9466 69.6689C22.0342 82.6898 44.1485 88.8528 74.8756 82.4689C115.8 73.9663 142.174 43.6684 131.305 18.7778C122.917 -0.430028 90.4232 0.128559 61.8244 9.00794M10.9466 69.6689C7.26686 65.3475 4.80165 60.2706 3.63927 54.6464C-0.220265 35.9718 18.6608 10.306 61.8244 9.00794M10.9466 69.6689C5.47966 52.0038 15.0283 31.2126 38.1887 18.7778C45.2429 14.9904 53.3632 11.635 61.8244 9.00794M10.9466 69.6689C12.7728 75.5697 16.2744 81.1216 21.5037 85.8968C37.0118 100.058 73.6882 103.021 106.109 86.6722M61.8244 9.00794C70.8297 8.73713 80.892 9.52698 92.0251 11.6623' stroke='%23DBFF42' stroke-width='4.7349'/%3E%3C/svg%3E\")",
            },
            colors: {
                purple: "#6100ff",
                yellow: "#DBFF42",
                orange: "#EE9B00",
                black: "#191A20",
                white: "#FFFFFB",
                gray: "#242424",
            }, // Extend Tailwind's default colors
            content: {
                sparks: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='161' height='157' viewBox='0 0 161 157' fill='none'%3E%3Cpath d='M34.2842 92.5295L53.4515 48.8912M58.5369 106.066L106.269 38.7473M78.0825 122.656L117.702 94.2622M90.8937 148.565L119.407 133.533M9.56776 79.5387L16.2264 52.3003' stroke='%23DBFF42' stroke-width='7.89474'/%3E%3C/svg%3E\")",
            },
            keyframes: {
                wiggle: {
                    "0%, 40%": { transform: "rotate(0deg)" },
                    "2%, 18%": { transform: "rotate(-3deg)" },
                    "20%, 38%": { transform: "rotate(3deg)" },
                },
                "wiggle-auto": {
                    "0%, 10%": { transform: "rotate(0deg)" },
                    "1%, 4%": { transform: "rotate(-3deg)" },
                    "5%, 9%": { transform: "rotate(3deg)" },
                },
                draw: {
                    "50%, 100%": {
                        "--path-length": 0,
                    },
                },
            },
            animation: {
              wiggle: "wiggle 800ms linear infinite",
              "wiggle-wiggle": "wiggle-auto 2s linear infinite",
              draw: "draw var(--duration, 1s) var(--delay, 0s) ease-in forwards var(--repeat, infinite)",
            },
            typography: ({ theme }) => ({
              DEFAULT: {
                css: {
                  a: {
                    '&:hover': {
                      color: theme('colors.purple.DEFAULT')
                    }
                  }
                }
              }
            })
        },
    },
    plugins: [
        require('@tailwindcss/typography'),
        plugin(require("./tw-forms.config.js")),
        plugin(function ({ addVariant }) {
            addVariant("menu", [
                "& .wp-block-navigation__container",
                "& .wp-block-navigation > ul",
            ])
            addVariant(
                "menu-item",
                "& > .wp-block-navigation > ul > .wp-block-navigation-link",
            )
            addVariant("menu-link", [
                "& > .wp-block-navigation > ul > .wp-block-navigation-link > a",
                "& .wp-block-navigation-item__content > div",
            ])
        }),
        plugin(function ({ addBase, matchUtilities, theme }) {
            addBase({
                ":root": {
                    "--tw-text-scale-min": "100%",
                    "--tw-text-scale-max": "999%",
                },
            })
            matchUtilities({
                "text-scale": (value) => ({
                    "--tw-text-scale": value,
                }),
            })
            matchUtilities(
                {
                    "text-min": (value) => ({
                        "--tw-text-scale-min": value,
                    }),
                    "text-max": (value) => ({
                        "--tw-text-scale-max": value,
                    }),
                },
                { values: theme("fontSize") },
            )
        }),
    ],
}
