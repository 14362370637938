import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default function (Alpine) {
    Alpine.directive(
        "emerge",
        (el, { value, expression, modifiers }, { evaluate, cleanup }) => {
            let options = evaluate(expression.length > 0 ? expression : "{}")

            if (modifiers.includes("scrub")) {
                doScrubbedAnimation(el, modifiers, options)
            } else {
                doAutoAnimation(el, modifiers, options)
            }
        },
    )
}

function doScrubbedAnimation(el, modifiers, options) {
    if (options.hasOwnProperty("z")) {
        gsap.set(el, { transformPerspective: 500 })
    }

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            scrub: modifierValue(modifiers, "scrub", true),
            start: `${modifierValue(modifiers, "start", "top")} ${modifierValue(
                modifiers,
                "start",
                "bottom",
                2,
            )}`,
            end: `${modifierValue(modifiers, "end", "top")} ${modifierValue(
                modifiers,
                "end",
                "50%",
                2,
            )}`,
        },
    })

    tl.from(el, options)
}

function doAutoAnimation(el, modifiers, options) {
    const defaultOptions = {
        opacity: 0,
        duration: 0.75,
        paused: true,
        ease: "power2.inOut",
    }

    if (! options.hasOwnProperty("xPercent")) {
        defaultOptions.x = modifiers.includes("right") ? -150 : 150
    }

    const animation = gsap.from(el, {
        ...defaultOptions,
        ...options,
    })

    ScrollTrigger.create({
        trigger: el,
        start: `${modifierValue(modifiers, "start", "top")} ${modifierValue(
            modifiers,
            "start",
            "75%",
            2,
        )}`,
        onEnter: () => animation.play(),
    })

    ScrollTrigger.create({
        trigger: el,
        start: `${modifierValue(modifiers, "reverse", "top")} ${modifierValue(
            modifiers,
            "reverse",
            "bottom",
            2,
        )}`,
        onLeaveBack: () =>
            modifiers.includes("reverse")
                ? animation.reverse()
                : animation.pause(0),
    })
}

function modifierValue(modifiers, key, fallback, position = 1) {
    // If the modifier isn't present, use the default.
    if (modifiers.indexOf(key) === -1) return fallback

    const rawValue = modifiers[modifiers.indexOf(key) + position]

    if (!rawValue) return fallback

    return rawValue
}
